<template>
  <base-section
    id="liket"
  >
    <base-section-heading title="Liket på fiket">
    <p>Liket på fiket är en revy med sketcher, sånger och en röd tråd. Vi befinner oss på ett fik som helt plötsligt får ett lik på halsen. I denna vrickade revy kan vad som helst hända, inget ämne är för banalt eller för komplext. En människa föds och dör, och en stund däremellan gör livet sina små krumsprång. 
    </p>
    <p>Revyn hade urpremiär i januari 2022 på Hällagården i Borensberg.</p>
    <p>
     Föreställningen är ca 120 minuter lång plus en paus mellan akterna.
    </p>
    <p>
Regi: Fredrik Zeijlon<br/>
Manus: Kerstin Bergström<br/>
Skådespelare: Edith Bergström, Kerstin Bergström, Joakim Carlsson, Lisa Eriksson, Lisa Ferm, Sanna Häger, Dan Lundin, Junie Lundin Zeijlon, Johan Millving, Svante Petré<br/>
Tekniker: Anders Karlsson<br/>
    </p>
    </base-section-heading>

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
      <v-col align="center">
    <v-responsive
      class="mx-auto"
    >
        <base-img
          width=354
          :src="require(`@/assets/liket.png`)"
        />
    </v-responsive>
      </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
    }),
  }
</script>
